import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sub_menu_visible: true,
    menu_num: "1",
  },
  getters: {
    getSmv (state) {
      return state.sub_menu_visible
    },
    getMenuNum (state) {
      return state.menu_num
    }
  },
  mutations: {
    showSubMenu (state, visible) {
      state.sub_menu_visible = visible
    },
    showMenuNumData (state, num) {
      state.menu_num = num
    },
    showMemberNumData (state, num) {
      sessionStorage.setItem('authority', num);
    }
  },
  actions: {
    callSubMenu ( { state, commit }, { visible } ) {
      commit('showSubMenu', visible)
    },
    callMenuNumData ({ state, commit }, { num }) {
      commit('showMenuNumData', num)
    },
    callMemberNumData ({ state, commit }, { num }) {
      commit('showMemberNumData', num)
    }
  },
  modules: {
  },
  plugins: [
    createPersistedState()
  ]
  
  /**
   * 특정 스토어만 저장 할때
   */
  // plugins: [
  //   createPersistedState({
  //     paths: [store]
  //   })
  // ]
})
