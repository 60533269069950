<template src="./index.html"/>

<script>

export default {
    name: 'members',
    data () {
        return {
            dialogVisible: false,
            formData: {
                id: '',
                password: ''
            },
            formItemData: [
                {label: '아이디', width: '120px', prop: 'id', placeholder:'아이디를 입력해주세요.', type: '', password: false},
                {label: '비밀번호', width: '120px', prop: 'password', placeholder:'비밀번호를 입력해주세요.', type: '', password: true}
            ],
            formRules: {
                id: [
                    {required: true, message: '아이디를 입력해주세요.', trigger: 'blur'}
                ],
                password: [
                    {required: true, message: '비밀번호를 입력해주세요.', trigger: 'blur'}
                ],
            }
        }
    },
    props: {
        isLogin: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        onSubmit(formName) {
            const vm = this;
            vm.$refs[formName].validate((valid) => {
                if(valid) {
                    vm.handleLogin();
                } else {
                    return
                }
            })
        },
        dialogClose() {
            const vm = this;
            vm.dialogVisible = false;
        },
        handleLogin() {
            const vm = this;
            let url = process.env.VUE_APP_LOGIN_SELECT;

            let params = {
                id: vm.formData.id,
                password: vm.formData.password
            };

            vm.$http.post(url, params).then((response) => {
                if(response.data.length != 0) {
                    this.$store.dispatch('callMemberNumData', { num: response.data[0].authority });
                    vm.dialogVisible = false;
                } else {
                    this.$message({
                        type: 'warning',
                        message: '아이디와 비밀번호가 없습니다.'
                    });
                    return
                }
            }).catch((error) => {
                console.log(error);
            });
        }
    },
    watch: {
        isLogin() {
            const vm = this;
            if(vm.isLogin == true) {
                vm.dialogVisible = true;
            }
        },
        dialogVisible(newValue, oldValue) {
            const vm = this;
            if(newValue == false) {
                vm.$emit('setLogin', vm.dialogVisible);
            }
        }
    }
}

</script>

<style lang="scss"  src="./index.scss" scoped />