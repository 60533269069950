<template src="./Layout.html"/>
    
<script>
import MenuData from '@/json/menu_data'
import MenuData2 from '@/json/menu_data2'
import SubMenuData from '@/json/sub_menu_data'
import router from '@/router'
import Members from '@/views/members/index'

export default {
    
    name: 'Layout',
    data () {
        return {
            main_list: [],
            master_main_list: [],
            main_menu_num: '',
            sub_menu_data: [],
            main_menu_select: '',
            main_menu_id_old: '',
            sub_menu_select: '',
            sub_menu_id_old: '',
            top_show : true,
            side_show : false,
            sub_show: false,
            mem_show: false,
            width : 0,
            drawer: false,
            direction: 'rtl',
            hover: false,
            click_visible: false,
        }
    },
    created() {
        this.selectDataList();
    },
    mounted() {
        window.addEventListener("resize", this.handleWidthReSize);
        this.handleWidthReSize();
    },
    beforeUnmount() {
        window.removeEventListener("resize", this.handleWidthReSize);
    },
    methods: {
        selectDataList() {
            const vm = this;
            vm.main_list = MenuData;
            vm.master_main_list = MenuData2;
            vm.sub_menu_data = SubMenuData;
        },
        handleTopSelect(data) {
            const vm = this;
            let subMenu = data.children;
            let imgOff = data.imageOff;
            let imgOver = data.imageOver;
            let newId = data.id;
            let data_path = subMenu[0].path;
            this.$store.dispatch('callSubMenu', { visible: true });
            this.$store.dispatch('callMenuNumData', { num: data.num });
            // if(vm.main_menu_select != imgOff) {
                let urlOver = null;
                let urlOff = null;

                // 메인 이미지 변경
                urlOver = require('@/assets/menu_image/' + imgOver);
                document.getElementById(newId).src = urlOver;
                
                if(vm.main_menu_select != "") {
                    urlOff = require('@/assets/menu_image/' + vm.main_menu_select);
                    document.getElementById(vm.main_menu_id_old).src = urlOff;
                } 
                else {
                    urlOff = require('@/assets/menu_image/menu0200_off.png');
                    document.getElementById('product').src = urlOff;
                }

                // 서브 이미지 변경(초기)
                let subId = null;
                let subImgOver = null;
                let subUrlOver = null;
                let subUrlOff = null;
                if(vm.sub_menu_select != "") {
                    subId = subMenu[0].id;
                    subImgOver = subMenu[0].imageOver;
                    subUrlOver = require('@/assets/menu_image/' + subImgOver);
                    document.getElementById(subId).src = subUrlOver;

                    subUrlOff = require('@/assets/menu_image/' + vm.sub_menu_select);
                    document.getElementById(vm.sub_menu_id_old).src = subUrlOff;
                } else {
                    subId = subMenu[0].id;
                    subImgOver = subMenu[0].imageOver;
                    subUrlOver = require('@/assets/menu_image/' + subImgOver);
                    document.getElementById(subId).src = subUrlOver;
                }
                

                
            // }
            
            vm.main_menu_select = imgOff;
            vm.main_menu_id_old = newId;
            vm.sub_menu_select = subMenu[0].imageOff;
            vm.sub_menu_id_old = subMenu[0].id;
            
            router.push(data_path);

        },

        handleTopSelect2(data) {
            let data_path = data.path;
            this.$store.dispatch('callSubMenu', { visible: false });

            router.push(data_path);
        },

        handleMainSelect() {
            const vm = this;
            let urlOff = null;
            let subUrlOff = null;
            if(vm.main_menu_select != '') {
                urlOff = require('@/assets/menu_image/' + vm.main_menu_select);
                document.getElementById(vm.main_menu_id_old).src = urlOff;

                subUrlOff = require('@/assets/menu_image/' + vm.sub_menu_select);
                document.getElementById(vm.sub_menu_id_old).src = subUrlOff;
            } else {
                urlOff = require('@/assets/menu_image/menu0200_off.png');
                document.getElementById('product').src = urlOff;
            }
            
            this.$store.dispatch('callSubMenu', { visible: false });
            router.push("/main");
        },

        handleSideSelect() {
            const vm = this;
            this.$store.dispatch('callSubMenu', { visible: true });
            // vm.sub_menu_data = data;
            vm.drawer = false;
        },

        handleMemSelect() {
            const vm = this;
            if(vm.authChk == '') {
                vm.mem_show = true;
            } else if (vm.authChk == '0' || vm.authChk == '1') {
                vm.$confirm('로그아웃 하시겠습니까?', '로그아웃', {
                    confirmButtonText: '확인',
                    cancelButtonText: '취소',
                    type: 'warning'
                }).then(() => {
                    this.$store.dispatch('callMemberNumData', { num: '' });
                    this.$message({
                        type: 'success',
                        message: '로그아웃 되었습니다.'
                    });
                    router.push("/main");
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '취소 되었습니다.'
                    });
                }).finally(() => {
                    setTimeout(function(){ window.location.reload() },500);
                });
            }

        },

        setLogin(row) {
            const vm = this;
            vm.mem_show = row;
            window.location.reload();
        },

        handleSubSelect(data) {
            const vm = this;
            let imgOff = data.imageOff;
            let imgOver = data.imageOver;
            let newId = data.id;
            if(vm.sub_menu_select == '') {
                vm.sub_menu_select = 'menu0201_off.png';
                vm.sub_menu_id_old = 'ita';
            }

            if(vm.sub_menu_select != imgOff) {
                let urlOver = require('@/assets/menu_image/' + imgOver);
                let urlOff = require('@/assets/menu_image/' + vm.sub_menu_select);
                document.getElementById(newId).src = urlOver;
                document.getElementById(vm.sub_menu_id_old).src = urlOff;
            }

            vm.sub_menu_select = imgOff;
            vm.sub_menu_id_old = newId;
        },

        handleFooterSelect(mainMenuData, subMenudata) {
            const vm = this;
            const mainM = mainMenuData;
            const subM = subMenudata;
            this.$store.dispatch('callSubMenu', { visible: true });
            this.$store.dispatch('callMenuNumData', { num: mainM.num });
            let mainUrlOver = null;
            let mainUrlOff = null;
            let mainId = mainM.id;
            let subUrlOver = null;
            let subUrlOff = null;
            let subId = subM.id;
            if(vm.main_menu_select == '') {
                mainUrlOver = require('@/assets/menu_image/' + mainM.imageOver);
                document.getElementById(mainId).src = mainUrlOver;
                subUrlOver = require('@/assets/menu_image/' + subM.imageOver);
                document.getElementById(subId).src = subUrlOver;
            } else {
                mainUrlOver = require('@/assets/menu_image/' + mainM.imageOver);
                document.getElementById(mainId).src = mainUrlOver;
                subUrlOver = require('@/assets/menu_image/' + subM.imageOver);
                document.getElementById(subId).src = subUrlOver;

                mainUrlOff = require('@/assets/menu_image/' + vm.main_menu_select);
                document.getElementById(vm.main_menu_id_old).src = mainUrlOff;
                subUrlOff = require('@/assets/menu_image/' + vm.sub_menu_select);
                document.getElementById(vm.sub_menu_id_old).src = subUrlOff;
            }

            vm.main_menu_select = mainM.imageOff;
            vm.main_menu_id_old = mainId;
            vm.sub_menu_select = subM.imageOff;
            vm.sub_menu_id_old = subId;

            router.push(subM.path);
        }, 

        showSubMenu(data) {
            const vm = this;
            
            if (vm.$route.path != '/main') {
                let arr = vm.$route.path.split('/');
                
                data.filter((val) => {
                    chk_sub_menu = val.children;

                    val.children.filter((sub) => {
                        if(sub.path == vm.$route.path) {
                            vm.main_menu_select = imgOff;
                            vm.main_menu_id_old = newId;
                            vm.sub_menu_select = vm.sub_menu_data[0].imageOff;
                            vm.sub_menu_id_old = vm.sub_menu_data[0].title;
                        }
                    })
                });
            }else {
                vm.sub_menu_data = data[1].children;
            }

        },

        /**
         * 화면크기에 따른 메뉴바  
        */
        handleWidthReSize(event) {
            const vm = this;
            const width = window.innerWidth;
            vm.top_show = width < 767 ? false : true;
            vm.side_show = width < 767 ? true : false;
            let sub = document.querySelector('#sub-menu');
            if(width < 767) {
                // sub.classList.remove('animate__fadeIn');
                // sub.classList.add('animate__fadeOut');
                if(!sub.classList.contains('dis-none')) {
                    // setTimeout(function(){ sub.classList.add('dis-none') },1001);
                    sub.classList.add('dis-none')
                }
                
            } else {
                if(sub.classList.contains('dis-none')) {
                    sub.classList.remove('dis-none');
                }
                // sub.classList.remove('animate__fadeOut');
                // sub.classList.add('animate__fadeIn')
            }
            if(vm.drawer === true) {
                vm.drawer = width < 767 ? true : false;
            }
        },

        /** 
         * 사이드 메뉴 닫기
        */
        handleClose(done) {
            this.drawer = false;
        },

        imageHover(chk, img, data) {
            if(data == 'member') {
                if(chk == true) {
                    document.querySelector('.member img').src = require('@/assets/menu_image/' + img);
                } else {
                    document.querySelector('.member img').src = require('@/assets/menu_image/' + img);
                }
            } else if(data == 'side') {
                if(chk == true) {
                    document.querySelector('.side-button img').src = require('@/assets/menu_image/' + img);
                } else {
                    document.querySelector('.side-button img').src = require('@/assets/menu_image/' + img);
                }
            }
            
        }

    },
    computed: {
        getSmv() {
            return this.$store.getters.getSmv
        },
        getSubMenuData() {
            const vm = this;
            let isData = null;
            if(vm.getMenuNumData == "1") {
                isData = true;
            } else {
                isData = false;
            }
            return isData
        },
        getMenuNumData() {
            return this.$store.getters.getMenuNum
        },
        authChk() {
            const sessionData = sessionStorage.getItem('authority');
            let data = '';
            if(sessionData != null) {
                data = sessionData;
            }

            return data
        }
    },
    beforeUpdate() {
    },
    watch: {
    },
    components: {
        Members
    }
}
</script>

<style lang="scss"  src="./Layout.scss" scoped />