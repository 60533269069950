import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/Layout'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Layout,
    redirect: 'main',
    children: [
      {
        path: 'main',
        name: '메인 화면',
        component: () => import('@/views/early/index')
      },
      {
        path: 'information/vision',
        name: '경영이념 및 철학',
        component: () => import('@/views/information/vision/index')
      },
      {
        path: 'information/history',
        name: '연혁',
        component: () => import('@/views/information/history/index')
      },
      {
        path: 'information/certification',
        name: '인증 및 수상',
        component: () => import('@/views/information/certification/index')
      },
      {
        path: 'information/organization',
        name: 'CI 및 BI',
        component: () => import('@/views/information/organization/index')
      },
      {
        path: 'information/location',
        name: '찾아오시는길',
        component: () => import('@/views/information/location/index')
      },
      {
        path: 'product/ita',
        name: 'ITA',
        component: () => import('@/views/product/ita/index')
      },
      {
        path: 'product/itb',
        name: 'ITB',
        component: () => import('@/views/product/itb/index')
      },
      {
        path: 'product/its',
        name: 'ITS',
        component: () => import('@/views/product/its/index')
      },
      {
        path: 'product/a100',
        name: 'A100',
        component: () => import('@/views/product/a100/index')
      },
      {
        path: 'product/a110',
        name: 'A110',
        component: () => import('@/views/product/a110/index')
      },
      {
        path: 'product/ips',
        name: 'IPS',
        component: () => import('@/views/product/ips/index')
      },
      {
        path: 'product/rtls_iot',
        name: 'IoT',
        component: () => import('@/views/product/rtls_iot/index')
      },
      {
        path: 'product/dct',
        name: 'DCT',
        component: () => import('@/views/product/dct/index')
      },
      {
        path: 'business/automotive',
        name: '자동차',
        component: () => import('@/views/business/automotive/index')
      },
      {
        path: 'business/iot',
        name: 'IOT',
        component: () => import('@/views/business/iot/index')
      },
      {
        path: 'business/firmware',
        name: '펌웨어',
        component: () => import('@/views/business/firmware/index')
      },
      {
        path: 'business/embedded',
        name: '임베디드',
        component: () => import('@/views/business/embedded/index')
      },
      {
        path: 'RandD/hardware',
        name: '정부R&D',
        component: () => import('@/views/RandD/hardware/index')
      },
      {
        path: 'RandD/softwar',
        name: '산업협력과제',
        component: () => import('@/views/RandD/softwar/index')
      },
      {
        path: 'RandD/bio_healthcare',
        name: '지원사업',
        component: () => import('@/views/RandD/bio_healthcare/index')
      },
      {
        path: 'RandD/ml',
        name: '자체연구사업',
        component: () => import('@/views/RandD/ml/index')
      },
      {
        path: 'partnership/researchInstitute',
        name: '연구소',
        component: () => import('@/views/partnership/researchInstitute/index')
      },
      {
        path: 'partnership/company',
        name: '기업체',
        component: () => import('@/views/partnership/company/index')
      },
      {
        path: 'partnership/academy',
        name: '대학교',
        component: () => import('@/views/partnership/academy/index')
      },
      {
        path: 'partnership/government',
        name: '정부기관',
        component: () => import('@/views/partnership/government/index')
      },
      {
        path: 'inventory',
        name: '인벤토리',
        component: () => import('@/views/inventory/index')
      },
      {
        path: 'inventory/:id',
        name: 'inventoryDetail',
        component: () => import('@/views/inventory/components/detail'),
        props: true
      },
      {
        path: 'stock',
        name: '스톡',
        component: () => import('@/views/stock/index')
      },
      {
        path: 'tool',
        name: '툴',
        component: () => import('@/views/tool/index')
      },
      {
        path: 'bookingcar',
        name: '부킹차',
        component: () => import('@/views/bookingcar/index')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
