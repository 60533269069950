import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import locale from 'element-ui/lib/locale/lang/en'
import axios from 'axios'
import "@/assets/style/app.scss"
import 'animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import VueCookies from "vue-cookies";

Vue.prototype.$http = axios;

Vue.use(ElementUI, { size: 'small', zIndex: 2000, locale });

Vue.use(VueCookies);
Vue.$cookies.config("1d");

Vue.config.productionTip = false

new Vue({
  created() {
    AOS.init({
      once: true,
      delay: "350"
    });
  },
  router,
  store,
  render: h => h(App)
}).$mount('#app')
